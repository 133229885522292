import { useCallback, useState } from 'react';
import API from 'utils/API';

const useFetchConfig = ({ onSuccess }) => {
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(false);

  const fetch = useCallback(
    (sessionId) => {
      if (!sessionId) {
        return;
      }
      setLoading(true);
      return API.fetchConfig(sessionId)
        .then(async (data) => {
          setConfig(data);
          onSuccess && (await onSuccess(data));
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
          setFetched(true);
        });
    },
    [onSuccess],
  );

  return {
    config,
    fetch,
    loading,
    fetched,
    error,
  };
};

export default useFetchConfig;
