import axios from 'axios';

export const downloadFile = async (fileUrl: string, cb: () => void) => {
  return downloadFileAbsoluteURL(fileUrl, cb);
};

export const downloadFileAbsoluteURL = async (
  url: string,
  cb: (param: Record<string, string | number>) => void,
) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'arraybuffer',
    onDownloadProgress: (e) => {
      const value = 50 + Math.round((e.loaded * 100) / e.total / 2);
      cb &&
        cb({
          status: value === 100 ? 'done' : 'progress',
          value,
        });
    },
  });

  const res = response.data;

  return res;
};
