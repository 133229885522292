import { SAssetPreview } from './Asset.styles';

interface AssetProps {
  previewImage: string;
  assetId: string;
  onDragStart: (e: React.DragEvent<HTMLDivElement>, assetId: string) => void;
}

const Asset = ({ previewImage, assetId, onDragStart }: AssetProps) => {
  return (
    <SAssetPreview onDragStart={(e) => onDragStart(e, assetId)}>
      <img src={previewImage} alt="assets" />
    </SAssetPreview>
  );
};

export default Asset;
