import { degrees, PDFDocument, rgb } from 'pdf-lib';

import { IMAGE_PlACE, SCALING_METHOD } from 'utils/constants';
import { getFileName } from 'utils/misc';

const getSizeInPixel = (sizeInMM) => {
  return (sizeInMM / 25.4) * 72;
};

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

const BLEED = getSizeInPixel(100);
const ONE_CM = getSizeInPixel(10);

const normalizeBleedConfig = (config = {}) => {
  let left, right, top, bottom;
  if (Object.keys(config) < 1) {
    throw new Error('Invalid bleed config', config);
  }
  if (config.all) {
    left = right = top = bottom = config.all;
  } else {
    left = config.left;
    right = config.right;
    top = config.top;
    bottom = config.bottom;
  }
  return {
    left: getSizeInPixel(left),
    right: getSizeInPixel(right),
    top: getSizeInPixel(top),
    bottom: getSizeInPixel(bottom),
  };
};

const createPDF = async (pdfFile, options, showBleed = true) => {
  const { type, place, rotation, dimensions, productSize } = options;
  let file;
  file = await rotate(pdfFile, {
    dimensions,
    rotation,
  });
  let bleed = options.bleed;
  bleed = normalizeBleedConfig(bleed);
  const width = getSizeInPixel(productSize.width) + bleed.right + bleed.left;
  const height = getSizeInPixel(productSize.height) + bleed.top + bleed.bottom;

  const optionsParams = {
    rotation,
    place,
    type,
    width,
    height,
    bleed,
  };

  switch (type) {
    case SCALING_METHOD.CROP:
      file = await createCropPDF(file, optionsParams);
      break;
    case SCALING_METHOD.STRETCH:
      file = await createStretchPDF(file, optionsParams);
      break;
    default:
      file = await createFitPDF(file, optionsParams);
  }
  if (showBleed) {
    file = await drawBleed(file, { width, height, bleed: bleed });
  } else {
    file = await drawTrimBox(file, { width, height, bleed });
    file = await drawCutMarks(file, { width, height, bleed });
  }
  if (options.flip) {
    file = await flipPDF(file);
  }
  return file;
};

const getScale = (srcPage, place, { width, height, bleed }, min = true) => {
  const fn = min ? 'min' : 'max';
  switch (place) {
    case IMAGE_PlACE.NET:
      return Math[fn]((width - BLEED) / srcPage.getWidth(), (height - BLEED) / srcPage.getHeight());
    case IMAGE_PlACE.NEGATIVE_WHITE_SPACE:
      return Math[fn](
        (width - BLEED + ONE_CM) / srcPage.getWidth(),
        (height - BLEED + ONE_CM) / srcPage.getHeight(),
      );
    default:
      return Math[fn](width / srcPage.getWidth(), height / srcPage.getHeight());
  }
};

const getBleed = (place) => {
  switch (place) {
    case IMAGE_PlACE.NET:
      return BLEED;
    case IMAGE_PlACE.NEGATIVE_WHITE_SPACE:
      return BLEED - ONE_CM;
    default:
      return 0;
  }
};

const finializePage = async (pdfDoc) => {
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

const generateBleedBox = ({ bleed, width, height } = {}) => {
  const { left, right, top, bottom } = bleed;
  return {
    x: left,
    y: bottom,
    width: width - right - left,
    height: height - top - bottom,
  };
};

const drawBleed = async (pdfFile, { width, height, bleed }) => {
  const { left, right, top, bottom } = bleed;
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const config = generateBleedBox({ bleed, height, width });
  const opacity = 0.3;

  srcPage.setBleedBox(config.x, config.y, config.width, config.height);
  srcPage.drawLine({
    start: { x: 0, y: 0 },
    end: { x: width, y: 0 },
    thickness: bottom * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: 0, y: height },
    end: { x: width, y: height },
    thickness: top * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: 0, y: height - top },
    end: { x: 0, y: bottom },
    thickness: left * 2,
    color: rgb(1, 0, 0),
    opacity,
  });
  srcPage.drawLine({
    start: { x: width, y: height - top },
    end: { x: width, y: bottom },
    thickness: right * 2,
    color: rgb(1, 0, 0),
    opacity,
  });

  return finializePage(sourcePdf);
};

const drawTrimBox = async (pdfFile, { width, height, bleed }) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const config = generateBleedBox({ bleed, height, width });

  srcPage.setTrimBox(config.x, config.y, config.width, config.height);

  return finializePage(sourcePdf);
};

const drawCutmarkLine = (page, { start, end } = {}) => {
  const outerThickness = getSizeInPixel(2);
  const innerThickness = getSizeInPixel(1);

  page.drawLine({
    start,
    end,
    thickness: outerThickness,
    color: rgb(1, 1, 1),
    opacity: 1,
  });

  page.drawLine({
    start,
    end,
    thickness: innerThickness,
    color: rgb(0, 0, 0),
    opacity: 1,
  });
};

const drawCutMarks = async (pdfFile, { width, height, bleed }) => {
  const margin = getSizeInPixel(3);
  const { right, left, top, bottom } = bleed;

  // load pdf file
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const topRightH = {
    start: { x: width - right + margin, y: height - top },
    end: { x: width - margin, y: height - top },
  };
  const topRightV = {
    start: { x: width - right, y: height - top + margin },
    end: { x: width - right, y: height - margin },
  };
  const bottomRightH = {
    start: { x: width - right + margin, y: bottom },
    end: { x: width - margin, y: bottom },
  };
  const bottomRightV = {
    start: { x: width - right, y: bottom - margin },
    end: { x: width - right, y: margin },
  };
  const bottomLeftH = {
    start: { x: margin, y: bottom },
    end: { x: left - margin, y: bottom },
  };
  const bottomLeftV = {
    start: { x: left, y: bottom - margin },
    end: { x: left, y: margin },
  };
  const topLeftH = {
    start: { x: margin, y: height - top },
    end: { x: left - margin, y: height - top },
  };
  const topLeftV = {
    start: { x: left, y: height - top + margin },
    end: { x: left, y: height - margin },
  };
  const cuts = [
    topRightH,
    topRightV,
    bottomRightH,
    bottomRightV,
    bottomLeftH,
    bottomLeftV,
    topLeftH,
    topLeftV,
  ];

  cuts.forEach((conifg) => {
    drawCutmarkLine(srcPage, conifg);
  });

  return finializePage(sourcePdf);
};

const createEmptyPage = (pdfDoc, { width, height }) => {
  const page = pdfDoc.addPage([width, height]);
  return page;
};

export const rotate = async (pdfFile, { rotation, dimensions } = {}) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);
  const pageAngle = srcPage.getRotation().angle;
  let degree = mod(-1 * (rotation + pageAngle), 360);

  const { width, height } = dimensions;

  let newHeight;
  let newWidth;
  if (degree === 90 || degree === 270) {
    newHeight = width;
    newWidth = height;
  } else {
    newHeight = height;
    newWidth = width;
  }

  const page = sourcePdf.addPage([newWidth, newHeight]);
  const embeddedPage = await sourcePdf.embedPage(srcPage);

  const dimensionsVsDegree = {
    0: [0, 0, newWidth, newHeight],
    90: [newWidth, 0, newHeight, newWidth],
    180: [width, height, newWidth, newHeight],
    270: [0, newHeight, newHeight, newWidth],
  };

  const [x, y, pageWidth, pageHeight] = dimensionsVsDegree[degree];
  page.drawPage(embeddedPage, {
    x,
    y,
    width: pageWidth,
    height: pageHeight,
    rotate: degrees(degree),
  });

  sourcePdf.removePage(0);
  return finializePage(sourcePdf);
};

export const flipPDF = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  srcPage.scale(-1, 1);

  return finializePage(sourcePdf);
};

export const flipPreviewPDF = async (rawFile) => {
  if (!rawFile) {
    return;
  }
  const pdfFile = await rawFile.arrayBuffer();
  const generatedFile = await flipPDF(pdfFile);
  return new Blob([generatedFile]);
};

export const createFitPDF = async (pdfFile, { place, width, height, bleed, rotation }) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  // Embed the source pdf into the target pdf
  const embeddedPage = await pdfDoc.embedPage(srcPage);
  const scale = getScale(srcPage, place, { width, height, bleed });

  const embeddedPageDims = embeddedPage.scale(scale);

  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  page.drawPage(embeddedPage, {
    ...embeddedPageDims,
    x: width / 2 - embeddedPageDims.width / 2,
    y: height / 2 - embeddedPageDims.height / 2,
  });

  return finializePage(pdfDoc);
};

export const createCropPDF = async (pdfFile, { place, width, height, bleed, rotation }) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const scale = getScale(srcPage, place, { width, height, bleed }, false);

  srcPage.scale(scale, scale);

  const FIT_DIRECTION = Object.freeze({
    WIDTH: 'WIDTH',
    HEIGHT: 'HEIGHT',
  });

  const fitDirection =
    srcPage.getWidth() - width <= srcPage.getHeight() - height
      ? FIT_DIRECTION.HEIGHT
      : FIT_DIRECTION.WIDTH;

  const left = fitDirection === FIT_DIRECTION.WIDTH ? (srcPage.getWidth() - width) / 2 : 0;
  const bottom = fitDirection === FIT_DIRECTION.HEIGHT ? (srcPage.getHeight() - height) / 2 : 0;

  // Embed the source pdf into the target pdf
  const embeddedPage = await pdfDoc.embedPage(srcPage, {
    top: bottom + height,
    left,
    bottom,
    right: left + width,
  });

  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  page.drawPage(embeddedPage, {
    width: page.getWidth(),
    height: page.getHeight(),
    x: 0,
    y: 0,
  });
  return finializePage(pdfDoc);
};

export const createStretchPDF = async (pdfFile, { place, width, height, rotation }) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Convert to array bytes
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  // Embed the source pdf into the target pdf
  const embeddedPage = await pdfDoc.embedPage(srcPage);

  const page = createEmptyPage(pdfDoc, { width, height, rotation });

  const bleed = getBleed(place);

  page.drawPage(embeddedPage, {
    width: page.getWidth() - bleed,
    height: page.getHeight() - bleed,
    x: bleed / 2,
    y: bleed / 2,
  });

  return finializePage(pdfDoc);
};

export const createPreviewPdf = async (rawFile, options) => {
  if (!rawFile) {
    return;
  }
  const pdfFile = await rawFile.arrayBuffer();
  const generatedFile = await createPDF(pdfFile, options, true);
  return new Blob([generatedFile]);
};

export const createProductionPdf = async (pdfFile, options) => {
  if (!pdfFile) {
    return;
  }
  const generatedFile = await createPDF(pdfFile, options, false);
  return generatedFile;
};

export const convertImageToPDF = async (file, { width, height } = {}) => {
  const imageBytes = typeof file === 'string' ? file : await file.arrayBuffer();

  const pdfDoc = await PDFDocument.create();

  const jpgImage = await pdfDoc.embedJpg(imageBytes);

  const page = pdfDoc.addPage([width, height]);

  page.drawImage(jpgImage);

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const convertImageToPDFAndGenerateFile = async (file, { width, height, filename } = {}) => {
  const pdfBytes = await convertImageToPDF(file, { width, height });

  return new File([pdfBytes], `${getFileName(filename)}.pdf`, {
    type: 'application/pdf',
  });
};

export const resize = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // get first source page
  const srcPage = sourcePdf.getPage(0);

  const ratio = 100 / srcPage.getWidth();

  srcPage.scale(ratio, ratio);

  const pdfBytes = await sourcePdf.save();

  return new File([pdfBytes], pdfFile.name);
};

export const getPageCount = async (pdfFile) => {
  const sourcePdf = await PDFDocument.load(pdfFile);
  const pageCount = await sourcePdf.getPageCount();
  return pageCount;
};

export const extractPages = async (pdfFile, pages) => {
  const sourcePdf = await PDFDocument.load(pdfFile);

  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  return Promise.all(
    pages.map(async (pageNum) => {
      const srcPage = sourcePdf.getPage(pageNum);
      const width = srcPage.getWidth();
      const height = srcPage.getHeight();

      // Embed the source pdf into the target pdf
      const embeddedPage = await pdfDoc.embedPage(srcPage);

      const page = createEmptyPage(pdfDoc, {
        width: srcPage.getWidth(),
        height: srcPage.getHeight(),
      });

      let degree = mod(-1 * (0 + srcPage.getRotation().angle), 360);

      const dimensionsVsDegree = {
        0: [0, 0, width, height],
        90: [width, 0, height, width],
        180: [width, height, width, height],
        270: [0, height, height, width],
      };

      const [x, y, pageWidth, pageHeight] = dimensionsVsDegree[degree];
      page.drawPage(embeddedPage, {
        x,
        y,
        width: pageWidth,
        height: pageHeight,
        rotate: degrees(degree),
      });
      const pdfBytes = await pdfDoc.save();

      return pdfBytes;
    }),
  );
};

export const combinePdfs = async (pdfFiles) => {
  if (!pdfFiles || !pdfFiles.length) {
    return;
  }
  const pdfDoc = await PDFDocument.create();
  for (const pdfFile of pdfFiles) {
    await pdfDoc.embedPdf(pdfFile);
    const [pdfPage] = await pdfDoc.embedPdf(pdfFile);
    const { width, height } = pdfPage.size();
    const page = pdfDoc.addPage([width, height]);
    page.drawPage(pdfPage);
  }
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};
