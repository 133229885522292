import styled from 'styled-components';

export const SContainer = styled.div``;

export const SItemWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  padding: 20px 0px;
`;

export const SItemName = styled.span`
  font-size: 16px;
`;
