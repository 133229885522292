import { PREVIEW_IMAGE_SIZE } from 'utils/constants';
import { pdfjs as PDFJS } from 'react-pdf';
import { convertImageToPDF } from 'utils/pdf';
import { IFile } from 'types/types';

interface IConvertPdfToImageOptions {
  scale: number;
  quality: number;
  width?: number;
}

export const getImageDimensions = (file: File) => {
  const dimensions = {} as { width: number; height: number };
  const img = new Image(); 
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    const { width, height } = img;
    dimensions.width = width;
    dimensions.height = height;
  };
  return dimensions;
};

export const getWidthForPreviewImage = (file: File) => {
  const { width } = getImageDimensions(file);
  const ratio = generatePreviewImageQualityRatio(file) || 0.3;
  return width * ratio;
};

export const blobToBase64 = async (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const convertPdfToImages = async (
  file: IFile,
  { scale, quality, width }: IConvertPdfToImageOptions = {
    scale: 1,
    quality: 1,
  },
) => {
  const url = URL.createObjectURL(file);
  const pdfDoc = await PDFJS.getDocument({ url }).promise;
  const numPages = pdfDoc.numPages;

  const images = await Promise.all(
    Array.from({ length: numPages }, (_, index) => index + 1).map(async (pageNum) => {
      const page = await pdfDoc.getPage(pageNum);
      const canvas = document.createElement('canvas');
      const canvasCtx = canvas.getContext('2d');

      if (width) scale = width / page.getViewport({ scale }).width;

      const viewport = page.getViewport({ scale });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: canvasCtx || {}, viewport }).promise;

      return {
        base64: canvas.toDataURL('image/jpeg', quality),
        width: viewport.width,
        height: viewport.height,
        originalWidth: page.getViewport({ scale: 1 }).width,
        originalHeight: page.getViewport({ scale: 1 }).height,
      };
    }),
  );

  return {
    getImages: () => images.map(({ base64 }) => base64),
    getImagesWithOriginalDimensions: () =>
      images.map(({ base64, originalWidth, originalHeight }) => ({
        base64,
        originalWidth,
        originalHeight,
      })),
    getImagesWithDimensions: () =>
      images.map(({ base64, width, height }) => ({ base64, width, height })),
    getImagesWithMetaData: () => images,
  };
};

export const compressPdf = async (file: IFile) => {
  const images = (await convertPdfToImages(file)).getImagesWithDimensions();
  const compressedPDFs = await Promise.all(
    images.map(async ({ base64, width, height }) => convertImageToPDF(base64, { width, height })),
  );
  return compressedPDFs;
};

export const convertPdfToImage = async (
  file: IFile,
  { scale = 1, quality = 1, width }: IConvertPdfToImageOptions,
) => {
  const images = await (await convertPdfToImages(file, { scale, quality, width })).getImages();
  return images?.[0];
};

export const generatePreviewImageQualityRatio = (file: File) => {
  const size = file?.size;
  if (!size) {
    return;
  }
  const ratio = Math.min(1, PREVIEW_IMAGE_SIZE / size);
  return ratio;
};
