import { nanoid } from 'nanoid';
import { defaultGeneratePdfValues } from './constants';

export const getFileName = (filename) => {
  return filename.split('.').slice(0, -1).join('.');
};

export const createSide = (file) => {
  return Object.assign(
    {
      id: nanoid(),
      actions: { rotation: 0, flip: false, ...defaultGeneratePdfValues },
    },
    !!file && { file },
  );
};

export const createEmptyProduct = (file, sidesCount = 0) => {
  const product = {
    id: nanoid(),
    count: 1,
    sides: [],
  };

  if (!!file) {
    product.sides.push(createSide(file));
  } else {
    Array(sidesCount)
      .fill(0)
      .forEach(() => {
        product.sides.push(createSide());
      });
  }
  return product;
};
