import { Card } from 'antd';
import Box from 'components/Box/Box';
import styled from 'styled-components';

export const SCard = styled(Card)`
  width: 100%;
  border-radius: 8px;
`;

export const SCardContent = styled(Box)`
  height: 265px;
  padding: 10px;
  justify-content: center;
  cursor: pointer;
`;

export const SDelete = styled(Box)`
  display: flex;
  justify-content: center;
`;
