import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SCount = styled.div`
  width: 35px;
  height: 34px;
  font-size: 20px;
  font-weight: 700;
  background: #ededed;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`;
