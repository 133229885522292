import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';

import { SContainer, SContent, SCount } from './Counter.styles';

const styles = {
  icons: { fontSize: 15, cursor: 'pointer' },
};

const Counter = ({ count, onIncrement, onDecrement }) => {
  return (
    <SContainer>
      <SContent>
        <MinusOutlined style={styles.icons} onClick={onDecrement} />
        <SCount>{count}</SCount>
        <PlusOutlined style={styles.icons} onClick={onIncrement} />
      </SContent>
    </SContainer>
  );
};

export default Counter;
