export const IMAGE_PlACE = Object.freeze({
  GROSS: 'GROSS',
  NET: 'NET',
  NEGATIVE_WHITE_SPACE: 'NEGATIVE_WHITE_SPACE',
});

export const SCALING_METHOD = Object.freeze({
  FIT: 'FIT',
  CROP: 'CROP',
  STRETCH: 'STRETCH',
});

export const CHUNK_SIZE_IN_MB = 20 * 1024 * 1024;

export const PDF_PREVIEW_WIDTH = 1000;

export const MAX_PRODUCTS_NUMBER = 10;

export const PREVIEW_IMAGE_SIZE = 120 * 1204;

export const DEFAULT_SIDES_COUNT = 2;

export const imagePlaces = [{ label: 'Afbeelding bruto', value: IMAGE_PlACE.GROSS }];

export const scalingMethods = [
  { label: 'Passend plaatsen', value: SCALING_METHOD.FIT },
  { label: 'Bijsnijden', value: SCALING_METHOD.CROP },
  { label: 'Uitrekken', value: SCALING_METHOD.STRETCH },
];

export const defaultGeneratePdfValues = {
  type: SCALING_METHOD.CROP,
  place: IMAGE_PlACE.GROSS,
};
