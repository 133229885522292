import { createGlobalStyle } from 'styled-components';

const GlobalStyle: any = createGlobalStyle`
  html {
    padding:0;
    margin:0;
  }
  body {
    padding:0;
    margin:0;
    background: rgb(249, 249, 249);
  }
  * {
    font-family: 'Roboto', sans-serif;
  }
`;

export default GlobalStyle;
