import { useState, useEffect } from 'react';

const useQueryParams = () => {
  const [params, setParams] = useState({});

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setParams(params);
  }, []);

  return params as { sessionId: string };
};

export default useQueryParams;
