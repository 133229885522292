import styled from 'styled-components';

export const SAssetPreview = styled.div`
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 8px;
  height: 130px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;
