import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #efefef;
`;

export const SCard = styled.div`
  width: 480px;
  background: #ffffff;
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const SFilename = styled.h5`
  color: #333;
  font-size: 14px;
  font-weight: 800;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
`;
