import styled from 'styled-components';
import { space, layout, color, flexbox, background, typography } from 'styled-system';

import type {
  SpaceProps,
  LayoutProps,
  ColorProps,
  FlexboxProps,
  BackgroundProps,
  TypographyProps,
} from 'styled-system';

type BoxProps = SpaceProps &
  LayoutProps &
  ColorProps &
  FlexboxProps &
  BackgroundProps &
  TypographyProps;

const Box = styled.div<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${background}
  ${typography}
`;

export default Box;
